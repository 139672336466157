.navWrapper {
    background-color: black;
    font-family: "Playfair Display", serif;
    position: fixed;
    color: white;
    width: 100%;
    top: 0;
    z-index: 99;
    border-bottom: 1px solid white;
    .navMain {
        padding: 0 20px;
        height: 72px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .logoContainer {
            position: relative;
            height: 100%;
            display: flex;
            align-items: center;
            @media (min-width: 1025px) {
                // flex-basis: auto;
            }
            .logo {
                position: relative;
                height: 30px;
                width: 120px;
            }
        }
        .SideNav {
            position: relative;
            height: 100%;
            display: flex;
            align-items: center;
            @media (min-width: 1025px) {
                flex-direction: row;
            }
            .navBurger {
                button {
                    cursor: pointer;
                }
                @media (min-width: 1025px) {
                    display: none;
                }
            }
            .navCollapse {
                position: fixed;
                display: none;
                width: 100%;
                top: 72px;
                left: 0;
                bottom: 0;
                background-color: black;
                padding: 120px 0 180px 0;
                @media (min-width: 1025px) {
                    position: static;
                    display: flex;
                    top: auto;
                    left: auto;
                    bottom: auto;
                    width: auto;
                    padding: 0;
                }
                ul {
                    display: flex;
                    flex-direction: column;
                    justify-content: space-around;
                    justify-items: center;
                    align-items: center;
                    @media (min-width: 1025px) {
                        flex-direction: row;
                        height: 100%;
                    }
                    li {
                        a {
                            font-family: "Playfair Display", serif;
                            font-size: 24px;
                            @media (min-width: 1025px) and (max-width: 1080px) {
                                font-size: 13px;
                            }
                            @media (min-width: 1081px) {
                                font-size: 16px;
                            }
                        }
                        @media (min-width: 1025px) {
                            font-size: 16px;
                            a {
                                height: 100%;
                                padding: 25px 16px;
                                transition: all 0.2s ease;
                                &:hover {
                                    background-color: rgba(65, 65, 65, 0.5);
                                }
                            }
                        }
                    }
                }
                animation: Fade 0.2s ease;
                @keyframes Fade {
                    from {
                        opacity: 0;
                    }
                    to {
                        opacity: 1;
                    }
                }
            }
            .navCollapse.ShowMenu {
                display: flex;
                justify-content: space-around;
            }
            .navAction {
                font-family: "Open Sans", sans-serif;
                position: fixed;
                display: flex;
                align-items: center;
                text-align: center;
                justify-content: center;
                bottom: 0;
                left: 0;
                width: 100%;
                background-color: #f8e0de;
                a {
                    border-left: 1px solid white;
                    font-weight: 600;
                    font-size: 13px;
                    flex-basis: 50%;
                }
                .linkText {
                    padding: 15px 0;
                }
                // a[href^="tel:"] {
                // }
                @media (max-width: 1024px) {
                    a {
                        color: #000;
                    }
                }
                @media (min-width: 1025px) {
                    background-color: transparent;
                    position: static;
                    gap: 12px;
                    padding-left: 12px;
                    bottom: auto;
                    left: auto;
                    width: auto;
                    height: auto;
                    a {
                        border: none;
                        font-weight: 500;
                        flex-basis: auto;
                        &:last-child {
                            padding: 10px 20px;
                        }
                    }
                }
            }
            .navBottom {
                font-family: "Open Sans", sans-serif;
                position: fixed;
                bottom: 0;
                left: 0;
                color: black;
                background-color: #f8e0de;
                width: 100%;
                height: 48px;
                display: flex;
                text-align: center;
                align-items: center;
                @media (min-width: 1025px) {
                    display: none;
                }
                a {
                    flex-basis: 50%;
                    font-size: 13px;
                    background-color: #f8e0de;
                    border-right: 1px solid white;
                    font-weight: 600;
                }
            }
        }
    }
}
