// Style when Text is in the Left Side
.leftSliceWrapper {
    display: flex;
    justify-items: center;
    justify-content: center;
    flex-direction: column-reverse;
    color: white;

    .textWrapper {
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 90px 10%;
        ul {
            list-style: inside;
            margin-bottom: 40px;
        }
        h2 {
            margin-bottom: 40px;
        }

        p {
            margin-bottom: 30px;
        }
    }
    .imageWrapper {
        position: relative;
        margin-top: 90px;
        // height: 480px;
    }
}

// Style when Text is in the Right Side
.rightSliceWrapper {
    display: flex;
    flex-direction: column-reverse;
    padding: 90px 10%;
    color: white;
    .textWrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
        h2 {
            padding: 40px 0;
        }
        p {
            margin-bottom: 30px;
        }
    }

    .imageWrapper {
        position: relative;
        display: flex;
        justify-content: center;
    }
}

//  < -- DESKTOP BREAK POINT -- >
@media (min-width: 1025px) {
    // Style when Text is in the Left Side
    .leftSliceWrapper {
        flex-direction: row;
        padding-right: 0;
        .textWrapper {
            flex-basis: 50%;
            text-align: left;
        }
        .imageWrapper {
            flex-basis: 50%;
            padding: 90px 0;
            display: flex;
            align-items: center;
            margin: 90px 0;
        }
    }

    // Style when Text is in the Left Side
    .rightSliceWrapper {
        flex-direction: row-reverse;
        padding: 0;
        .textWrapper {
            text-align: left;
            flex-basis: 50%;
            padding: 90px 10% 90px 0;
            h2 {
                padding-top: 0;
            }
        }
        .imageWrapper {
            flex-basis: 50%;
            justify-items: center;
            align-items: center;
        }
    }
}
