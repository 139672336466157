.heroWrapper {
    position: relative;
    background-repeat: no-repeat;
    background-size: auto;
    height: 100vh;
    width: 100%;
    color: white;
    z-index: 0;
    text-align: center;

    &:after {
        position: absolute;
        display: block;
        width: 100%;
        height: 100vh;
        left: 0;
        top: 0;
        content: "";
        background-color: rgba(0, 0, 0, 0.5);
        z-index: 0;
    }
    .contentWrapper {
        position: relative;
        top: 33%;
        z-index: 1;
        padding: 0 5%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        @media (min-width: 1025px) {
            top: 40%;
        }
        h1 {
            font-size: 40px;
            letter-spacing: 3px;
            font-weight: 700;
            @media (min-width: 1025px) {
                letter-spacing: 5px;
                font-size: 70px;
                margin-bottom: 0;
            }
        }
        h2 {
            @media (min-width: 1025px) {
                font-size: 36px;
            }
        }

        p {
            max-width: 280px;
            margin-bottom: 70px;
            @media (min-width: 1025px) {
                max-width: 480px;
                margin-bottom: 90px;
            }
        }
    }
}

.heroBreak {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50vh;
    overflow: hidden;
    background-repeat: no-repeat;
    background-size: auto;
    z-index: 0;
    color: white;
    .contentWrapper {
        position: relative;
        top: 16%;
        width: 100%;
        z-index: 1;
    }
    &:after {
        position: absolute;
        display: block;
        width: 100%;
        height: 50vh;
        left: 0;
        top: 0;
        content: "";
        background-color: rgba(0, 0, 0, 0.1);
        z-index: 0;
    }
    h1 {
        padding: 0 5%;
        font-size: 30px;
        font-weight: 700;
        text-align: center;
        @media (min-width: 1025px) {
            font-size: 40px;
        }
    }
}
