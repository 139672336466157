.cardsWrapper {
    // LEFT SIDE COMPONENT
    .leftSide {
        background-color: white;
        color: black;
        .leftHead {
            text-align: center;
            padding-bottom: 30px;
            h2 {
                padding-bottom: 40px;
            }
        }
        .leftMenu {
            background-color: white;
            color: black;
            font-size: 14px;
            padding-bottom: 40px;
            button {
                display: flex;
                justify-content: space-between;
                font-family: "Playfair Display", serif;
                font-weight: 600;
                padding: 16px;
            }
            .content {
                background-color: #89898c;
                color: white;
                padding: 40px 10%;
                h3 {
                    font-size: 24px;
                    padding-bottom: 15px;
                }
                h4 {
                    padding-bottom: 15px;
                    font-size: 20px;
                }
                ul {
                    list-style: inside;
                    padding-bottom: 15px;
                }
                p {
                    padding-bottom: 15px;
                    margin: 0;
                }
                div {
                    margin-top: 15px;
                    text-align: center;
                }
            }
        }
    }
    // RIGHT SIDE COMPONENT
    .rightSide {
        background-color: #89898c;
        color: white;
    }
}

@media (min-width: 1025px) {
    .cardsWrapper {
        display: flex;
        flex-direction: row;
        .leftSide {
            flex-basis: 50%;

            .leftMenu {
                padding-left: 10%;
                width: 100%;

                .listButton {
                    font-family: "Playfair Display", serif;
                    width: 100%;
                    cursor: pointer;
                    text-align: left;
                    display: flex;
                    font-weight: 600;
                    align-items: center;
                    justify-content: space-between;
                    font-size: 16px;
                    padding: 16px;
                    border-top: 1px solid #e2e8f0;
                    border-radius: 0;
                    &:hover,
                    &:focus {
                        background-color: #89898c;
                        color: white;
                    }
                }
            }
        }
        .rightSide {
            flex-basis: 50%;
            h3 {
                font-size: 34px;
                margin-bottom: 30px;
            }
            h4 {
                padding-bottom: 15px;
                font-size: 22px;
            }
            ul {
                list-style: inside;
                padding-bottom: 15px;
            }
            p {
                padding-bottom: 15px;
                margin: 0;
            }
        }
    }
}
