.reviewsWrapper {
    display: flex;
    justify-content: center;
    text-align: center;
    color: black;
    .container {
        @media (min-width: 1025px) {
            max-width: 1280px;
            display: flex;
            flex-direction: column;
            justify-content: center;
        }
        .carousel {
            max-width: 200px;
            @media (min-width: 375px) {
                max-width: 280px;
            }
            @media (min-width: 475px) {
                max-width: 380px;
            }
            @media (min-width: 618px) {
                max-width: 450px;
            }
            @media (min-width: 768px) {
                max-width: 500px;
            }
            text-align: center;
            @media (min-width: 1025px) {
                max-width: 600px;
            }

            p {
                &:nth-child(1) {
                    text-align: center;
                    font-style: italic;
                    font-size: 20px;
                    margin-bottom: 45px;
                    color: #89898c;
                    line-height: 1.3;
                }
                &:nth-child(2) {
                    border-top: 1px solid #b7b7b8;
                    border-bottom: 1px solid #b7b7b8;
                    padding: 10px 0;
                    margin-bottom: 50px;
                    text-align: center;
                    a {
                        color: #89898c;
                    }
                }
            }
        }
    }
}
.sideDots {
    margin: 3px;
}

.rightArrow {
    position: absolute;
    top: 20%;
    margin-left: 10px;
}

.leftArrow {
    position: absolute;
    top: 20%;
    margin-left: -50px;
}
