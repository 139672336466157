.tarifsWrapper {
    text-align: center;
    background-color: black;
    color: white;
    display: flex;
    justify-content: center;
    .container {
        max-width: 1280px;

        h2 {
            padding-bottom: 40px;
        }
        p {
            margin-bottom: 15px;
        }
        .priceWrapper {
            margin: 40px 0;
            @media (min-width: 1025px) {
                display: grid;
                grid-template-columns: 1fr 1fr;
                gap: 24px 32px;
            }
            .priceItem {
                border: 1px solid #89898c;
                padding: 20px;
                margin: 24px 0;
                display: grid;

                justify-items: center;
                align-items: center;
                @media (min-width: 1025px) {
                    margin: 0;
                    grid-template-columns: 1fr 1fr;
                }
                .priceItemTitle {
                    order: 1;
                    h3 {
                        font-size: 30px;
                        padding-bottom: 5px;
                    }
                    @media (min-width: 1025px) {
                    }
                }
                .priceItemContent {
                    order: 3;
                    @media (min-width: 1025px) {
                    }
                }
                .priceItemCircle {
                    order: 2;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    font-family: "Playfair Display", serif;
                    background-color: #f8e0de;
                    color: black;
                    border-radius: 50%;
                    height: 130px;
                    width: 130px;
                    margin: 16px 0;
                    font-size: 22px;
                    font-weight: 500;
                    @media (min-width: 1025px) {
                        grid-row: span 3;
                    }
                    span {
                        font-family: "Open Sans", sans-serif;
                        font-size: 12px;
                        font-weight: 600;
                        text-transform: uppercase;
                    }
                }
                .priceItemButton {
                    order: 4;
                    margin: 16px 0;

                    @media (min-width: 1025px) {
                        width: 100%;
                        a {
                            padding: 10px 16px;
                        }
                    }
                }
            }
        }
    }
}
