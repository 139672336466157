.ctaWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    h2 {
        margin-bottom: 25px;
        line-height: 1.3em;
    }
    p {
        margin-bottom: 25px;
    }
}
