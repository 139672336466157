.ButtonPink {
    font-family: "Open Sans", sans-serif;
    padding: 10px 20px;
    font-size: 13px;
    background-color: #f8e0de;
    color: #000;
    font-weight: 600;
    transition: all 0.2s ease;
    &:hover {
        background-color: #f4ccc9;
    }
}

.ButtonTransparent {
    font-family: "Open Sans", sans-serif;
    padding: 0 20px;
    background-color: transparent;
    color: #000;
    border: 1px solid #000;
    font-weight: 600;
    transition: all 0.2s ease;
    font-size: 13px;
    padding: 10px 20px;
    &:hover {
        background-color: #f4ccc9;
        color: #000;
        border: 1px solid #f4ccc9;
    }
}
